<template>
  <div class="p-6 w-full bg-white rounded-xl shadow-md flex flex-col items-center space-y-4">
    <div class="text-xl font-medium text-black">Generate Text</div>
    <textarea v-model="prompt" placeholder="Enter your prompt here" rows="4" class="w-full border-2 p-2 rounded"></textarea>
    <button @click="generateText" :disabled="loading" class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full">
      Generate Text
    </button>
    <!-- Loading indicator -->
    <div v-if="loading" class="text-center py-2">Loading...</div>
    <!-- Display generated text -->
    <div v-if="generatedText && !loading" class="w-full">
      <h3 class="font-bold">Generated Text</h3>
      <div class="mt-4 p-4 bg-gray-100 rounded">
        <p>{{ generatedText }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentUser } from '@/router/auth'
import axios from 'axios';

export default {
  data() {
    return {
      prompt: '',
      generatedText: '',
      loading: false,
    };
  },
  methods: {
    async generateText() {
      this.loading = true; // Start loading
      try {
        const user = await getCurrentUser();
        const idToken = await user.getIdToken(true);

        const formData = new FormData();
        formData.append('prompt', this.prompt);

        // If your generate-text endpoint expects more form fields, add them here
        const backendUrl = process.env.VUE_APP_FASTAPI_BACKEND_URL;

        const response = await axios.post(`${backendUrl}/generate-text/`, formData, {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        });

        this.generatedText = response.data.text; // Adjust according to the actual response structure
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false; // Stop loading regardless of the outcome
      }
    },
  },
};
</script>

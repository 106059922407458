import { getAuth, onAuthStateChanged } from 'firebase/auth';

export const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, user => {
            unsubscribe(); // Detach the listener after getting the auth state
            resolve(user);
        }, reject);
    });
};

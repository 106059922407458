import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../views/LoginView.vue';
import HomeView from '../views/HomeView.vue';
import { getCurrentUser } from '@/router/auth';

const routes = [
    {
        path: '/',
        name: 'Login',
        component: LoginView,
    },
    {
        path: '/home',
        name: 'Home',
        component: HomeView,
        meta: { requiresAuth: true },
    },
    // Redirects to Login if no other routes are matched
    {
        path: '/:catchAll(.*)',
        redirect: '/',
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (requiresAuth) {
        try {
            // Ensure you're calling the function here
            const user = await getCurrentUser();
            if (!user) {
                next('/');
            } else {
                next();
            }
        } catch (error) {
            console.error(error);
            next('/');
        }
    } else {
        next();
    }
});

export default router;

<template>
  <div class="relative min-h-screen bg-gray-100 overflow-auto">
    <nav class="bg-gray-800 text-white p-4">
      <div class="flex justify-start items-center">
        <h1 class="text-lg font-bold px-4">Jar Jar Toolkit</h1>
      </div>
    </nav>
    <div class="absolute top-0 left-0 right-0 flex justify-center" style="margin-top: -2rem;">
      <div class="w-full max-w-md px-4 mt-16"> <!-- mt-16 adjusts for the nav -->
        <UserAuthForm />
      </div>
    </div>
  </div>
</template>

<script>
import UserAuthForm from "@/components/UserAuthForm.vue";

export default {
  components: {
    UserAuthForm,
  },
};
</script>

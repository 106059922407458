import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDmjfnRQh8axnm2sSBdbpuy6jetXAPZ6zY",
    authDomain: "sublime-spider-416614.firebaseapp.com",
    projectId: "sublime-spider-416614",
    storageBucket: "sublime-spider-416614.appspot.com",
    messagingSenderId: "889816954061",
    appId: "1:889816954061:web:2850e860b308b4bdc9a584"
};

const app = initializeApp(firebaseConfig);

export default app;

<template>
  <div class="p-6 w-full bg-white rounded-xl shadow-md flex flex-col items-center space-y-4">
    <h2 class="text-xl font-medium text-black">Multimodal Image Query</h2>
    <input type="file" @change="selectFile" class="w-full border-2 p-2 rounded"/>
    <input v-model="query" placeholder="Enter query" class="w-full border-2 p-2 rounded"/>
    <button @click="multimodalImageQuery" :disabled="loading" class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full">
      Generate Text
    </button>
    <!-- Loading indicator -->
    <div v-if="loading" class="text-center py-2">Loading...</div>

    <p v-if="generatedText && !loading" class="bg-gray-100 p-4 rounded shadow-inner w-full text-center">Generated Text: {{ generatedText }}</p>
  </div>
</template>

<script>
import {getCurrentUser} from "@/router/auth";
import axios from 'axios';

export default {
  data() {
    return {
      file: null,
      query: '',
      generatedText: '',
      loading: false, // Add this line
    };
  },
  methods: {
    selectFile(event) {
      this.file = event.target.files[0];
    },
    async multimodalImageQuery() {
      this.loading = true; // Start loading
      try {
        const user = await getCurrentUser();
        const idToken = await user.getIdToken(true);

        const formData = new FormData();
        formData.append('file', this.file);
        formData.append('query', this.query);
        const backendUrl = process.env.VUE_APP_FASTAPI_BACKEND_URL;

        const response = await axios.post(`${backendUrl}/multimodal-image-query/`, formData, {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        });

        this.generatedText = response.data.text;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false; // Stop loading regardless of the outcome
      }
    },
  },
};
</script>

<template>
  <div class="p-6 w-full bg-white rounded-xl shadow-md flex flex-col items-center space-y-4">
    <div class="text-xl font-medium text-black">Create Image</div>
    <input v-model="prompt" placeholder="Enter prompt" class="w-full border-2 p-2 rounded" />
    <button @click="createImage" :disabled="loading"
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full">
      Create Image
    </button>
    <!-- Loading indicator -->
    <div v-if="loading" class="text-center py-2">Loading...</div>

    <div v-if="imageUrls.length && !loading" class="w-full">
      <h3 class="font-bold">Generated Images</h3>
      <div class="grid grid-cols-1 gap-4 mt-4">
        <img v-for="url in imageUrls" :src="url" :key="url" class="rounded shadow"  alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
import {getCurrentUser} from "@/router/auth";
import axios from 'axios';

export default {
  data() {
    return {
      prompt: '',
      imageUrls: [],
      loading: false, // Add this line
    };
  },
  methods: {
    async createImage() {
      this.loading = true; // Start loading
      try {
        const user = await getCurrentUser();
        const idToken = await user.getIdToken(true);

        const formData = new FormData();
        formData.append('prompt', this.prompt);
        formData.append('n', 1); // Adjust as necessary
        const backendUrl = process.env.VUE_APP_FASTAPI_BACKEND_URL;

        const response = await axios.post(`${backendUrl}/create-image/`, formData, {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        });

        this.imageUrls = response.data.images;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false; // Stop loading regardless of the outcome
      }
    },
  },
};
</script>

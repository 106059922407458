<template>
  <div class="min-h-screen bg-gray-100">
    <nav class="bg-gray-800 text-white p-4">
      <div class="flex justify-between items-center w-full"> <!-- Updated this line -->
        <h1 class="text-lg font-bold px-4">Jar Jar Toolkit</h1>
        <div class="px-4">
          <button @click="logout" class="text-base bg-gray-600 hover:bg-red-700 text-white font-bold py-1 px-3 rounded">
            Logout
          </button>
        </div>
      </div>
    </nav>
    <div class="py-10">
      <div class="container custom-grid-alignment mx-auto grid grid-cols-1 md:grid-cols-2 gap-4">
        <CreateImage v-if="showCreateImage" />
        <MultimodalImageQuery v-if="showMultimodalImageQuery" />
        <GenerateText v-if="showGenerateText" />
      </div>
    </div>
  </div>
</template>


<script>
import { getAuth, signOut } from 'firebase/auth';
import CreateImage from '@/components/CreateImage.vue';
import MultimodalImageQuery from '@/components/MultimodalImageQuery.vue';
import GenerateText from '@/components/GenerateText.vue';

export default {
  components: {
    CreateImage,
    MultimodalImageQuery,
    GenerateText,
  },
  data() {
    return {
      showCreateImage: true,
      showMultimodalImageQuery: true,
      showGenerateText: true,
    };
  },
  methods: {
    toggleCreateImage() {
      this.showCreateImage = !this.showCreateImage;
    },
    toggleMultimodalImageQuery() {
      this.showMultimodalImageQuery = !this.showMultimodalImageQuery;
    },
    toggleGenerateText() {
      this.showGenerateText = !this.showGenerateText;
    },
    logout() {
      const auth = getAuth();
      signOut(auth).then(() => {
        console.log('User logged out');
        // Redirect or perform additional cleanup if necessary
        this.$router.push('/'); // Redirect to login or any other page
      }).catch((error) => {
        console.error('Logout Error:', error);
      });
    }
  },
};
</script>

<style scoped>
.custom-grid-alignment {
  align-items: start; /* Aligns items at the start of the grid container */
}
</style>

<template>
  <div class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8 bg-white p-6 rounded-xl shadow-md">
      <h2 class="text-center text-3xl font-extrabold text-gray-900">
        {{ isLoginMode ? 'Sign in to your account' : 'Register an account' }}
      </h2>
      <form class="mt-8 space-y-6" @submit.prevent="handleSubmit">
        <input type="hidden" name="remember" value="true">
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <input v-model="email" id="email-address" name="email" type="email" autocomplete="email" required
                   class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                   placeholder="Email address">
          </div>
          <div>
            <input v-model="password" id="password" name="password" type="password" autocomplete="current-password"
                   required
                   class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                   placeholder="Password">
          </div>
        </div>

        <!-- Loading Indicator -->
        <div v-if="loading" class="text-center py-2">Loading...</div>

        <!-- Submission Button -->
        <button
            type="submit"
            :disabled="loading"
            :class="[
              'group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2',
              isLoginMode ? 'bg-blue-500 hover:bg-blue-700 focus:ring-blue-500' : 'bg-green-500 hover:bg-green-700 focus:ring-green-500'
            ]">
          {{ isLoginMode ? 'Sign in' : 'Register' }}
        </button>

        <!-- Toggle Mode Link -->
        <div class="text-sm text-center">
          <a @click="toggleMode" class="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer">
            {{ isLoginMode ? 'Need an account? Register' : 'Already have an account? Sign in' }}
          </a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';

export default {
  data() {
    return {
      email: '',
      password: '',
      passwordConfirm: '',
      isLoginMode: true,
      loading: false,
    };
  },
  methods: {
    handleSubmit: function () {
      this.loading = true;
      const auth = getAuth(); // Get the Auth service instance

      if (this.isLoginMode) {
        // Handling user login
        signInWithEmailAndPassword(auth, this.email, this.password)
            .then((userCredential) => {
              // Signed in
              console.log('Logged in user:', userCredential.user);
              this.loading = false;
              this.$router.push('/home');
            })
            .catch((error) => {
              console.error('Error signing in:', error.message);
              this.loading = false;
            });
      } else {
        // Handling new user registration
        createUserWithEmailAndPassword(auth, this.email, this.password)
            .then((userCredential) => {
              // Registered
              console.log('Registered user:', userCredential.user);
              this.loading = false;
              // Redirect or perform additional actions
            })
            .catch((error) => {
              console.error('Error registering:', error.message);
              this.loading = false;
              // Optionally display the error message to the user
            });
      }
    },
    toggleMode() {
      this.isLoginMode = !this.isLoginMode;
    },
  },
};
</script>
